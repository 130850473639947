<template>
  <b-container class="wrapper">
    <sprint-test v-if="detail && moduleId === 2" isRedo :mediaUrls="mediaUrls"></sprint-test>
    <big-test ref="big" v-if="detail && moduleId !== 2" isRedo></big-test>
  </b-container>
</template>

<script>
import sprintTest from "@/views/english-small/sprint-test.vue";
import BigTest from "@/views/english-big/english-big-training-questions.vue";
import { GetOneQuestionHistory } from "@/api/english-entry";
import { mapState,mapMutations } from "vuex";
 
export default {
  name: "answer-details",
  data() {
    return {
      detail: null,
      moduleId: "",
      mediaUrls: []
    };
  },
  components: {
    sprintTest,
    BigTest,
  },
  created() { 
    this.getData();
  },
  activated() {
    this.getData();
  },
  computed: mapState({ questionNumber: (state) => state.questionNumber }),
  // eslint-disable-next-line no-unused-vars
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      if (!vm.redoQuestion.questionId || vm.redoQuestion.stemId === undefined) {
        return vm.$router.replace({
          name: "complete",
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("logDetail");
    sessionStorage.removeItem("trainingAnswer");
    this.setLogDetail("");
    this.setUserAnswers("");
    next();
  },
  methods: {
    ...mapMutations([
      "setLogDetail",
      "setUserAnswers",
      "setTrainingBeginTime"
    ]),
    getData() {
      this.detail = {};
      if (!this.redoQuestion) {
        return this.$router.replace({
          name: "complete",
        });
      }
      return GetOneQuestionHistory({
        questionId: this.redoQuestion.questionId,
        stemId: this.redoQuestion.stemId,
      }).then((res) => {
        this.moduleId = +res.data.result.module;
        this.detail = res.data.result;
        this.mediaUrls = res.data.result.mediaUrls
        this.$store.commit("setLogDetail", this.detail);
        this.setTrainingBeginTime(Date.parse(new Date()));
        try {
          this.$refs.big.resetData();
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: $bg-white;
  padding: 0;
}
</style>
